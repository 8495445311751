<template>
  <b-card title="Enlaces">
    <b-row>
      <b-col md="6" class="my-1">
        <b-button
          variant="primary"
          v-b-modal.modal-tipo-entrada
          @click="accion = 'registrar'"
          class="mr-1"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">Nuevo</span>
        </b-button>
        <b-button
          @click="actualizarLista()"
          variant="secondary"
          class="btn-icon mr-1"
        >
          <feather-icon icon="RotateCcwIcon" />
        </b-button>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label=""
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Buscar.."
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-overlay :show="loading">
          <b-table
            class="sm"
            responsive="sm"
            ref="selectableTable"
            selectable
            select-mode="single"
            hover
            :small="true"
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            @row-selected="onRowSelected"
            show-empty
            empty-text="No se encontraron registros coincidentes"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <i class="feather icon-disc primary" />
              </template>

              <template v-else>
                <i class="feather icon-circle" />
              </template>
            </template>
                     <template #cell(tipoenlace)="data">
          <b-badge :variant="estado[1][data.value]">
            {{ estado[0][data.value] }}
          </b-badge>
        </template>


            <template #cell(accion)="data">
              <b-button
                v-b-modal.modal-tipo-entrada
                @click="abriComponente(data.item)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-tooltip.hover.bottom="'Editar'"
                class="btn-icon mr-1"
                size="sm"
              >
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                @click="confirmar_eliminar(data.item.id)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                v-b-tooltip.hover.bottom="'Eliminar'"
                class="btn-icon"
                size="sm"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <EnlaceNuevoEditar
      :accion="accion"
      :item="item"
      @cerrarComponente="cerrarComponente"
      @actualizarLista="actualizarLista"
    ></EnlaceNuevoEditar>
  </b-card>
</template>

<script>
import axiosIns from "@/libs/axios";
import {
  BCard,
  BCardText,
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BOverlay,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import EnlaceNuevoEditar from "@/views/apariencia/enlaces/EnlaceNuevoEditar.vue";
//import axios from 'axios'
export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    EnlaceNuevoEditar,
    BOverlay,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      items: [],
      fields: [
        "nombre",
        "url",
        { key: "tipoenlace", label: "Tipo Enlace", sortable: false },
        "accion"],
      selected: [],
      filter: null,
      filterOn: [],
      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      accion: "",
      loading: false,
      estado: [
        {
          1: "Interno",
          2: "Externo",
        },
        {
          1: "light-secondary",
          2: "light-primary",
        },
      ],
      item: [],
    };
  },
  created() {
    this.loading = true;
    this.listar();
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    selectThirdRow() {
      this.$refs.selectableTable.selectRow(2);
    },
    unselectThirdRow() {
      this.$refs.selectableTable.unselectRow(2);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    listar() {
      var variable = "?tipoenlace=2";
      var url = `/enlaces${variable}`;
      axiosIns
        .get(url)
        .then((res) => {
          this.items = res.data.data;
          this.totalRows = this.items.length;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    actualizarLista() {
      this.loading = true;
      this.listar();
      this.accion = "";
      this.item = [];
    },
    abriComponente(item) {
      this.accion = "actualizar";
      this.item = item;
    },
    cerrarComponente() {
      this.accion = "";
      this.item = [];
      this.actualizarLista();
    },
    confirmar_eliminar(id) {
      axiosIns
        .get("/enlaces/existe_grupo/" + id)
        .then((res) => {
          let respuesta = res.data;
          if (respuesta) {
            this.$swal({
              icon: "warning",
              title: "Advertencia!",
              timer: 2000,
              text: "No se puede eliminar.",
              confirmButtonText: "Aceptar",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          } else {
            this.$swal({
              title: "Esta seguro de eliminar ?",
              text: "¡No podrás revertir esto!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Eliminar",
              cancelButtonText: "Cancelar",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.eliminar(id);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    eliminar(id) {
      axiosIns
        .delete("/enlaces/" + id)
        .then((res) => {
          this.$bvToast.toast("Exito", {
            title: "Se elimino correctamente",
            variant: "success",
            solid: false,
          });
          this.actualizarLista();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
</style>