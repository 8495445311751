<template>
  <b-modal
    id="modal-tipo-entrada"
    ref="my-modal"
    :title="titulo"
    no-close-on-backdrop
    ok-title="Guardar"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    @cancel="cerrarModal"
    @close="cerrarModal"
    @ok.prevent="validar"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- nombre -->
          <b-col cols="12">
            <b-form-group label-for="Nombre">
               <label for="Nombre"
                        >Nombre <span class="text-danger">(*)</span></label
                      >
              <validation-provider
                #default="{ errors }"
                name="Nombre"
                rules="required|min:4"
              >
                <b-form-input
                  id="nombre"
                  v-model="enlace.nombre"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nombre Enlace"
                />
                <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.nombre"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- url -->
          <b-col cols="12">
            <b-form-group label-for="url">
               <label for="url"
                        >Url <span class="text-danger">(*)</span></label
                      >
              <validation-provider
                #default="{ errors }"
                name="URL"
                rules="required|min:4"
              >
                <b-form-input
                  id="url"
                  v-model="enlace.url"
                  :state="errors.length > 0 ? false : null"
                  placeholder="url Enlace"
                />
                <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.url"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Tipo Enlace" label-for="tipo-enlaces">
              <v-select
                id="tipo-enlaces"
                v-model="tipoenlace"
                label="title"
                :options="enlaceOpciones"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <!-- svg enlace -->
          <b-col cols="12">
            <b-form-group label="Icono" label-for="icono">
              <validation-provider #default="{ errors }" name="Icono" rules="">
                <b-form-textarea
                  id="icono"
                  v-model="enlace.icono"
                  :state="errors.length > 0 ? false : null"
                  placeholder="SVG icono"
                />
                <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.icono"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- imagen url -->
          <b-col cols="12">
            <div class="border rounded p-2">
              <h4 class="text-center">
                <b>Imagen Enlace</b>
              </h4>
              <div class="text-center">
                <b-form-group label="" label-for="">
                  <div class="box-imagen box-imagen1 border rounded">
                    <b-img
                      v-if="imagen"
                      :src="imagen"
                      center
                      fluid
                      thumbnail
                      class="rounded"
                      alt="Card image cap"
                    />
                  </div>
                </b-form-group>
                <b-button
                  v-b-modal.modal-seleccionar-imagen
                  variant="flat-dark"
                  size="sm"
                >
                  <feather-icon icon="ImageIcon" class="mr-50" />
                  <span class="align-middle">Elegir Imagen</span>
                </b-button>
              </div>
            </div>
          </b-col>

          <!-- Estado -->
        </b-row>
      </b-form>
    </validation-observer>
    <ImagenComponent @enviarImagen="recibirImagen"></ImagenComponent>
  </b-modal>
  <!--Componente Imagen -->
</template>
<script>
import axios from "@axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BAlert,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BImg,
  BFormSelect,
  BFormTextarea
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import ImagenComponent from "@/componentes/ImagenComponent.vue";
import vSelect from "vue-select";

export default {
  components: {
    BButton,
    BModal,
    BAlert,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BForm,
    BRow,
    BCol,
    BImg,
    BFormSelect,
    vSelect,
    ImagenComponent,
  },
  props: ["accion", "item"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    accion: function () {
      this.errores = [];
      if (this.accion) {
        this.abrir(this.accion, this.item);
      }
    },
    nombre: function () {
      this.errores.nombre = [];
    },
    descripcion: function () {
      this.errores.descripcion = [];
    },
  },
  data() {
    return {
      id: null,
      enlaceOpciones: [
        { id: 1, title: "Interno" },
        { id: 2, title: "Externo" },
      ],
      tipoenlace: { id: 1, title: "Interno" },
      enlace: {
        nombre: "",
        url: "",
        icono: "",
      },
      nombre: "",
      imagen: "",
      url: "",
      descripcion: "",
      estado: 1,
      estadoOpciones: [
        { value: 1, text: "Modificable" },
        { value: 0, text: "No modificable" },
      ],
      titulo: "",
      required,
      submitted: false,
      errores: [],
    };
  },
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.titulo == "Nuevo Enlace") {
              this.registrar();
            } else {
              this.actualizar();
            }
          }
        }
      });
    },
    abrir(accion, data = []) {
      switch (accion) {
        case "registrar": {
          this.titulo = "Nuevo Enlace";
          this.enlace.nombre = "";
          this.enlace.url = "";
          this.enlace.icono = "";
          this.imagen = "";
          break;
        }
        case "actualizar": {
          this.titulo = "Editar Enlace";
          this.enlace = data;
          this.imagen = data.imagen;
          const valor = this.enlaceOpciones.find(
            (estado) => estado.id === data.tipoenlace
          );
          this.tipoenlace = { id: valor.id, title: valor.title };
          this.id = data["id"];
          // this.nombre = data["nombre"];
          // this.estado = data["estado"];
          break;
        }
      }
    },
    cerrarModal() {
      this.$emit("cerrarComponente");
    },
    actualizarLista(item) {
      this.$emit("actualizarLista", item);
      this.submitted = false;
    },
    registrar() {
      this.enlace.imagen = this.imagen;
      this.enlace.tipoenlace=this.tipoenlace.id;

      axios
        .post("/enlaces", this.enlace)
        .then((res) => {
          this.$nextTick(() => {
            this.$refs["my-modal"].toggle("#toggle-btn");
          });
          this.actualizarLista(res.data);
          this.errores = [];
        })
        .catch((err) => {
          this.submitted = false;
          this.errores = err.response.data.errors;
        });
    },
    actualizar() {
      this.enlace.imagen = this.imagen;
      this.enlace.tipoenlace=this.tipoenlace.id;
      axios
        .put("/enlaces/" + this.id, this.enlace)
        .then((res) => {
          this.$nextTick(() => {
            this.$refs["my-modal"].toggle("#toggle-btn");
          });
          this.actualizarLista();
          this.errores = [];
        })
        .catch((err) => {
          this.submitted = false;
          this.errores = err.response.data.errors;
        });
    },
    recibirImagen(imagen) {
      this.imagen = imagen.url;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.box-imagen {
  height: 13rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}
</style>